<template>
  <div class="main_r">
    <div class="second_con">
      <div class="weizhi"><span>你当前所在的位置：</span> <a href="/first">网站首页</a>&nbsp; >&nbsp;新闻资讯&nbsp; 
      </div>
      <div class="newslist">
        <div class="news_date_con" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)" style="cursor: pointer;">
          <div class="news_date l"> <span>{{ item.date.substring(8, 10) }}</span> {{ item.date.substring(0, 4) }}-{{
            item.date.substring(5, 7) }}</div>
          <div class="news_t" >
            <h3><a class="post1" :title="item.title" >{{ item.title }}</a></h3>
            <p class="text" v-html="item.content"> </p>
          </div>
          <div class="clear"></div>
        </div>
        <el-pagination style="text-align: center;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
  <!-- <div class="clear"></div> -->
</template>

<script>
import { listNews } from "@/api/news";
export default {
  data() {
    return {
      //总条数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      newsList: [],
    }
  },
  created() {
    this.getNews();
  },
  components: {},

  methods: {
    toDetail(id) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          id: id
        }
      })
    },
    getNews() {
      listNews({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.newsList = res.rows;
        this.total = res.total;
      })
    },

    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getNews();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getNews();
    },
  }
}
</script>

<style scoped>
.main_r {
  width: 1180px;
  min-height: 0;
  overflow: hidden;
  margin: 30px auto 20px auto;
}


.second_con {
  background: #fff;
  min-height: 800px;
  border: 1px solid #dadada;
  margin: 0 0 0 10px;
}

.second_con .weizhi {
  background: #ce2029;
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  padding-left: 28px;
  color: #ebebeb
}

.second_con .weizhi span {
  display: inline-block;
  font-size: 12px;
  color: #ebebeb;
  line-height: 33px;
  height: 37px;
  font-weight: normal;
  padding-left: 20px;
}

.second_con .weizhi a {
  color: #ebebeb;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}

.second_con .weizhi a:hover {
  color: #fff;
}

.newslist {
  width: 920px;
  margin: 0 auto;
}

.news_date_con {
  height: 70px;
  padding: 30px 0 30px 0;
  border-bottom: 1px dashed #ddd;
}

.news_date {
  width: 65px;
  border: 1px solid #ccc;
  font-size: 13px;
  color: #ccc;
  text-align: center;
  line-height: 25px;
  float: left;
  margin-right: 30px;
}

.news_date span {
  line-height: 45px;
  height: 45px;
  display: block;
  font-size: 28px;
  text-align: center;
  background: #ccc;
  color: #fff
}

.news_t {
  width: 920px;
  text-align: left;
  line-height: 20px;
  font-size: 12px;
  color: #999;
}

.news_t a {
  line-height: 30px;
  color: #666;
  font-size: 14px;
  text-decoration: none;
}

.news_t a:hover {
  color: #000000;
  cursor: pointer;
}

.news_t span {
  float: right
}

.text {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.clear {
  clear: both
}
</style>