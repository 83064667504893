<template>
    <div class="main_r">
        <div class="second_con">
            <div class="weizhi"><span>你当前所在的位置：</span> <a href="/first">网站首页</a>&nbsp; >&nbsp;产品展示&nbsp; >&nbsp;<a
                    @click="proShow" style="cursor: pointer;">{{
                        this.title }}</a>&nbsp; >&nbsp;{{ this.name }}</div>
            <div class="second">
                <div class="biao">{{ this.name }}</div>
                <div class="biao1">类别：{{ this.title }} 日期：{{ this.date }}</div>
                <div class="info_content">
                    <div>
                        <p style="text-align: center;">
                            <img :src="this.imgUrl" />
                        </p>
                    </div>
                    <div class="ql-editor" v-html="this.detail">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="clear"></div> -->
</template>
  
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { listPro } from "@/api/pro";
export default {
    data() {
        return {
            id: this.$route.params.id,
            typeId:null,
            title: null,
            name: null,
            date: null,
            imgUrl: null,
            detail: null,
            type: null,
            proList: []
        }
    },
    created() {
        this.getPro();
    },
    components: {},

    methods: {
        proShow() {
            this.$router.push({
                name: 'show',
                params: {
                    id: this.typeId
                }
            })
        },
        getPro() {
            listPro().then(res => {
                this.proList = res.rows;
                this.proList.forEach(p => {
                    if (p.id == this.id) {
                        this.name = p.name;
                        this.title = p.title;
                        this.date = p.date;
                        this.imgUrl = p.imgUrl;
                        this.type = p.type;
                        this.detail=p.detail;
                        this.typeId=p.typeId;
                    }
                })
            })
        }
    }
}
</script>
  
<style scoped>
.main_r {
    width: 1180px;
    min-height: 0;
    overflow: hidden;
    margin: 30px auto 20px auto;
}


.second_con {
    background: #fff;
    min-height: 800px;
    border: 1px solid #dadada;
    margin: 0 0 0 10px;
}

.second_con .weizhi {
    background: #ce2029;
    height: 37px;
    line-height: 37px;
    font-size: 12px;
    padding-left: 28px;
    color: #ebebeb
}

.second_con .weizhi span {
    display: inline-block;
    font-size: 12px;
    color: #ebebeb;
    line-height: 33px;
    height: 37px;
    font-weight: normal;
    padding-left: 20px;
}

.second_con .weizhi a {
    color: #ebebeb;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
}

.second_con .weizhi a:hover {
    color: #fff;
}

.second_con .second {
    padding: 20px 40px 10px 40px;
    font-size: 14px;
    line-height: 29px;
    color: #484848;
}

.second_con .second .jianjie {
    line-height: 28px;
    font-size: 14px;
}

.biao {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    margin: 5px 0 0 0;
}

.biao1 {
    line-height: 30px;
    margin: 0 0 25px 10px;
    text-align: center;
}

.info_content {
    color: #2e2e2e;
    overflow: hidden;
    padding-bottom: 10px;
    font-size: 13px;
}

.info_content img {
    max-width: 800px;
    min-width: 400px;
}
</style>