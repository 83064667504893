<template>
  <div>
    <div class="product_title_fenlei">
      <div class="product_title">
        <h2><strong>产品展示</strong> / PRODUCTS SHOW</h2>
      </div>
    </div>

    <div class="product" style="height: 50px; padding: 0">
      <div class="fenlei">
        <ul style="margin-left: 10%">
          <li v-for="item in typeList" :key="item.id" @click="proShow(item.id)">
            <a style="font-size: 18px" :title="item.title">{{ item.title }}</a>
            <div class="hid hidden" id="fs96"></div>
          </li>
        </ul>
      </div>
    </div>


    <div class="pro">
      <div class="friend">
        <div class="mr_frbox">
          <div class="mr_frUl">
            <div class="tempWrap" id="picscroll3">
              <ul id="mr_fu" style="width:9999px">
                <li style="float: left; width: 279px;cursor: pointer;" v-for="item in showList" :key="item.id" @click="toDetail(item.id)"> 
                  <a>
                     <img :src="item.imgUrl" width="262" :title="item.name" :alt="item.name">
                    <em>{{ item.name }}</em>
                    <p v-html="item.detail"></p>
                    <div class="mm"></div>
                  </a> 
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listProType } from "@/api/proType";
import { listShow } from "@/api/pro";
export default {
  data() {
    return {
      typeList: [],
      showList:[]
    };
  },
  created() {
    this.getType();
    this.getShow();
  },
  components: {},

  methods: {
    toDetail(id) {
      this.$router.push({
        name: 'ShowDetail',
        params: {
          id: id
        }
      })
    },
    proShow(id) {
      this.$router.push({
        name: 'show',
        params: {
          id: id
        }
      })
    },
    getType() {
      listProType().then(resp => {
        this.typeList = resp.rows;
      })
    },
    getShow(){
      listShow().then(res=>{
        this.showList=res;
      })
    }
  },
};
</script>

<style scoped lang="scss">
.product_title_fenlei {
  padding: 65px 0;
}

a {
  text-decoration: none;
}

.product_title {
  width: 1150px;
  margin: 0 auto;
  border: 1px solid #ce2029;
  height: 42px;
}

.product_title h2 {
  text-align: center;
  background: #fff;
  height: 45px;
  line-height: 45px;
  width: 380px;
  margin: 0 auto;
  margin-top: -23px;
  font-size: 18px;
  color: #000000;
}

.product_title h2 span {
  color: #df1818;
  font-weight: bold;
}

.product_title h2 strong {
  color: #ce2029;
  font-size: 28px;
  padding: 0 20px 0 0;
}

.product_title p {
  text-align: center;
  background: #fff;
  height: 40px;
  line-height: 40px;
  width: 750px;
  margin: 0 auto;
  font-size: 14px;
  color: #888888;
  text-transform: uppercase;
}

/*product*/
.product {
  background: url(@/assets/images/chanpinbg.jpg) no-repeat center bottom;
  padding: 20px 0 50px 0;
  height: 519px;
  // margin: 0 auto;
}

.fenlei {
  width: 1180px;
  margin: 0 auto;
  margin-bottom: 25px;
  background: url(@/assets/images/fenlei.jpg) repeat-x left top;
  position: relative;
  top: -25px;
  // text-align: center;
}

.fenlei ul {
  overflow: hidden;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fenlei li {
  line-height: 62px;
  float: left;
  height: 72px;
  text-align: center;
}

.fenlei li a {
  display: block;
  height: 62px;
  line-height: 62px;
  color: #444;
  padding: 0 24px;
  font-size: 14px;
  border-right: 1px solid #d8d8d8;
}

.fenlei li a:hover {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background: url(@/assets/images/redhover.png) no-repeat center top;
  height: 72px;
  border-right: 0 solid #d8d8d8;
  cursor: pointer;
}

.fenlei li .hid a {
  width: 146px;
  padding: 0 0 0 30px;
  height: 40px;
  line-height: 40px;
  background: #f9e5bd;
  margin: 0 auto;
  margin-bottom: 5px;
  color: #5b2b11;
}

.fenlei li .hid a:hover {
  color: #000;
}


.pro {
  width: 1180px;
  margin: 0 auto
}

.friend {
  height: auto;
  margin: 0px auto;
  overflow: hidden;
  width: 1180px;
}


.mr_frBtnL {
  cursor: pointer;
  display: inline;
  float: left;
  height: 46px;
  margin: 80px 10px 0 0;
  width: 28px;
}

.mr_frUl {
  width: 1180px;
  overflow: hidden
}

.mr_frBtnR {
  float: right;
  margin-top: 80px;
  cursor: pointer;
  width: 28px;
  height: 46px;
}

.mr_frUl ul li {
  display: inline;
  float: left;
  /*height:363px;*/
  width: 279px;
  overflow: hidden;
  position: relative;
  margin-right: 19px;
  background: #ffffff;
  text-align: center;
}

.mr_frUl ul li a {
  padding: 8px 8px 19px 8px;
  display: block;
  background: #efefee;
  border: #ccc 1px solid;
  height: 380px;
}

.mr_frUl ul li a img {
  width: 262px;
  height: 220px;
  border-bottom: 1px solid #ccc
}

.mr_frUl ul li em {
  display: block;
  line-height: 45px;
  color: #333333;
  font-style: normal;
  font-size: 14px
}

.mr_frUl ul li a:hover em{
  font-weight: 600;
}

.mr_frUl .mm {
  width: 26px;
  margin: 0 auto;
  height: 26px;
  line-height: 30px;
  color: #fff;
}

.mr_frUl p {
  text-align: left;
  color: #888888;
  height: 70px;
  line-height: 22px;
  padding: 0 10px 15px 10px;
  /*height:44px*/
  
  white-space: normal;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}


</style>