import request from '@/utils/request'

// 查询业务范围列表
export function listYewu(query) {
  return request({
    url: '/ws/yewu/list',
    method: 'get',
    params: query
  })
}


