<template>
  <div class="h_line">
    <div class="h_line_c">
      <ul>
        <li>
           河北甲壳虫建筑工程有限公司<span>{{ getCurDate() }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  methods: {
    getCurDate() {
      var today = new Date();
      var day;
      var date;
      if (today.getDay() == 0) day = "星期日";
      if (today.getDay() == 1) day = "星期一";
      if (today.getDay() == 2) day = "星期二";
      if (today.getDay() == 3) day = "星期三";
      if (today.getDay() == 4) day = "星期四";
      if (today.getDay() == 5) day = "星期五";
      if (today.getDay() == 6) day = "星期六";

      var thisyear = today.getYear();
      if (thisyear < 1900) {
        thisyear = thisyear + 1900;
      }
      date =
        thisyear +
        "年" +
        (today.getMonth() + 1) +
        "月" +
        today.getDate() +
        "日 " +
        day;

      return date;
    },
  },
};
</script>

<style scoped>
.h_line {
  width: 100%;
  height: 30px;
  line-height: 30px;
  /* background: url(@/assets/images/topbg.jpg) no-repeat center top / cover; */
  background-color: #ce2029;
}
.h_line_c {
  width: 1180px;
  margin: 0 auto;
  height: 30px;
}
.h_line_c ul {
  height: 30px;
}
.h_line_c li {
  float: left;
  height: 30px;
  line-height: 30px;
  color: #e9e9e9;
}
.h_line_c li a {
  color: #e9e9e9;
  text-transform: uppercase;
}
</style>