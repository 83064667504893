import request from '@/utils/request'

// 查询客户案例列表
export function listAnli(query) {
  return request({
    url: '/ws/anli/list',
    method: 'get',
    params: query
  })
}

// 查询首页展示客户案例
export function listShow(query) {
  return request({
    url: '/ws/anli/show',
    method: 'get',
    params: query
  })
}

