<template>
  <div id="app">

     <apptop></apptop>

    <!-- 占位符 -->
    <router-view />
    <Link></Link>
    <Footer></Footer>
  </div>
</template>


<script>
import Apptop from "@/components/apptop.vue"
import Link from "@/components/Link.vue"
import Footer from "@/components/Footer.vue"
export default {
  data () {
    return {
    }
  },

  components: {Apptop,Link,Footer},

  methods: {}
}
</script>

<style lang="scss">
*{
  margin: 0px;
  padding: 0px;
}

body{font-size:12px;  min-width:1180px; }

li{
  list-style: none;
}

.clear{
  clear: both;
}
</style>
