<template>
    <div class="main_r">
        <div class="second_con">
            <div class="weizhi"><span>你当前所在的位置：</span> <a href="/">网站首页</a>&nbsp; >&nbsp;客户案例</div>
            <div class="m10_pro_list">
                <div class="ki24">
                    <a  v-for="item in anliList" :key="item.id" :title="item.title" @click="toDetail(item.id)" style="cursor: pointer;">
                        <div class="ki241">
                            <div class="ki241img"><img :src="item.imgUrl" :alt="item.title" border="0" /></div>
                            <div class="ki2411">{{ item.title }}</div>
                            <div class="hszz1"></div>
                        </div>
                    </a>

                    <div class="clear"></div>
                    <el-pagination style="text-align: center;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageNum" :page-sizes="[9, 15, 30, 60]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>


                <!--底部三个联系方式-->
                <div class="lanmuc-liaxx">
                    <div class="cpxxyd">
                        <div class="lix">联系方式</div>
                        <div class="lxnr">
                            <ul>
                                <li v-for="item in lianxiList" :key="item.id">
                                    <p>{{ item.phone }}</p>
                                    <p><img :src="item.erWei" /></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
import { listLianxi } from "@/api/lianxi";
import {  listAnli } from "@/api/anli";
export default {
    data() {
        return {
            //总条数
            total: 0,
            pageNum: 1,
            pageSize: 9,
            lianxiList: [],
            anliList: [],
        }
    },
    created() {
        this.getlianxi();
        this.getAnli();
    },
    components: {},

    methods: {
        toDetail(id){
            this.$router.push({
                name:'AnliDetail',
                params:{
                    id:id
                }
            })
            // window,location.reload();
        },
        getlianxi() {
            listLianxi().then(res => {
                this.lianxiList = res.rows;
            })
        },
        getAnli() {
            listAnli({ pageNum: this.pageNum,
                pageSize: this.pageSize}).then(res => {
                this.anliList = res.rows;
                this.total=res.total;
            })
        },
         //分页
         handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize=val;
            this.getAnli();
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum=val;
            this.getAnli();
        },

    }
}
</script>

<style scoped>
/*erji*/


.main_r {
    width: 1180px;
    min-height: 0;
    overflow: hidden;
    margin: 30px auto 20px auto;
}


.second_con {
    background: #fff;
    min-height: 957px;
    border: 1px solid #dadada;
    margin: 0 0 0 10px;
}

.second_con .weizhi {
    background: #ce2029;
    height: 37px;
    line-height: 37px;
    font-size: 12px;
    padding-left: 28px;
    color: #ebebeb
}

.second_con .weizhi span {
    display: inline-block;
    font-size: 12px;
    color: #ebebeb;
    line-height: 33px;
    height: 37px;
    font-weight: normal;
    padding-left: 20px;
}

.second_con .weizhi a {
    color: #ebebeb;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
}

.second_con .weizhi a:hover {
    color: #fff;
}

.second_con .second {
    padding: 20px 40px 10px 40px;
    font-size: 14px;
    line-height: 29px;
    color: #484848;
}

.second_con .second .jianjie {
    line-height: 28px;
    font-size: 14px;
}

.lanmuc-liaxx {
    width: 100%;
    height: auto;
    margin: 0 auto;
    
}

.lanmuc-liaxx .cpxxyd {
    width: 95%;
    float: left;
    height: 240px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid #ccc;
    margin-right: 3%;
}

.lanmuc-liaxx .lix {
    width: 90%;
    float: left;
    height: 40px;
    padding: 0 5%;
    text-align: center;
    background: #ccc;
    font-size: 20px;
    line-height: 40px;
    background: #ce2029;
    color: #fff;
}

.lanmuc-liaxx .lxnr {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 20px;
}
.lanmuc-liaxx .lxnr ul{
    display: flex;
  flex-direction: row;
  justify-content: center;
}
.lanmuc-liaxx .lxnr ul li {
    width: 31%;
    height: auto;
    float: left;
    margin: 0 1%;
    text-align: center;
    font-size: 18px;
    line-height: 20px
}

.lanmuc-liaxx .lxnr ul li img {
    width: 160px;
    height: 160px;
}

.m10_pro_list {
    overflow: hidden;
    margin: 0 auto;
    width: 920px;
    padding: 30px 0 0 50px
}



.ki24 a {
    /* float: left; */
    display: inline-block;
    margin: 0 54px 20px 0;
    text-align: center
}

.ki241 {
    width: 252px;
    height: 300px;
    float: left;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
}

.ki241:hover .hszz1 {
    background: rgba(0, 0, 0, 0);
    transition: 1s all;
}

.ki24 img {
    float: left;
    transition: 1s all;
    border: 1px solid #e9e9e9;
    width: 248px;
    height: 200px;
}

.ki241img {
    width: 250px;
    height: 200px;
    overflow: hidden;
}

.ki241:hover .ki241img img {
    transform: scale(1.1);
    transition: 1s all;
}

.ki2411 {
    line-height: 48px;
    color: #333333;
    font-size: 14px;
}

.ki24 .hszz1 {
    width: 250px;
    height: 200px;
    transition: 1s all;
    /* background: rgba(0, 0, 0, 0.1); */
    position: absolute;
    top: 0px;
    /* border: 1px red solid; */
}

.ki24 .more {
    width: 250px;
    margin: 0 auto;
    height: 24px;
    background: #2d2d2d
}

.ki24 .more {
    display: block;
    line-height: 24px;
    color: #ddd;
    text-transform: uppercase;
    font-size: 12px;
}

.ki24 .more span {
    width: 122px;
    text-align: center;
    float: right;
    /* display: inline-block; */
    background: #ce2029;
    color: #ddd
}
</style>