import Vue from "vue";
import VueRouter from "vue-router";

import home from "@/views/home/index.vue"
import First from "@/views/first/index.vue"
import Company from "@/views/company/index.vue"
import  Yewu  from "@/views/yewu/index.vue";
import Aptitude from "@/views/aptitude/index.vue";
import AptitudeDetail from "@/views/aptitude/detail/index.vue";
import Anli from "@/views/anli/index.vue";
import AnliDetail from "@/views/anli/detail/index.vue";
import Show from "@/views/pro/show.vue";
import ShowDetail from "@/views/pro/detail/index.vue";
import News from "@/views/news/index.vue";
import NewsDetail from "@/views/news/detail/index.vue";
import Job from "@/views/job/index.vue";
import Connection from "@/views/connection/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,


    

  //   children:[
  //     {
  //       path: "/first",
  //       name: "First",
  //       component: First,
  //     },

  //     {
  //       path: "/company",
  //       name: "Company",
  //       component: Company,
  //     },
  //     {
  //       path: "/yewu/:id",
  //       name: "Yewu",
  //       component: Yewu,
  //     },
  //     {
  //       path:"/aptitude",
  //       name:"Aptitude",
  //       component:Aptitude,
  //     },
  //     {
  //       path: "/aptitudeDetail/:id",
  //       name: "AptitudeDetail",
  //       component: AptitudeDetail,
  //     },
  //     {
  //       path: "/anli",
  //       name: "Anli",
  //       component: Anli,
  //     },
  //     {
  //       path: "/anli/:id",
  //       name: "AnliDetail",
  //       component: AnliDetail,
  //     },
  //     {
  //       path: "/pro/:id",
  //       name: "show",
  //       component: Show,
  //     },
  //     {
  //       path: "/pro/detail/:id",
  //       name: "ShowDetail",
  //       component: ShowDetail,
  //     },
  //     {
  //       path: "/news",
  //       name: "news",
  //       component: News,
  //     },
  //     {
  //       path: "/news/detail/:id",
  //       name: "newsDetail",
  //       component: NewsDetail,
  //     },
  //     {
  //       path: "/job",
  //       name: "job",
  //       component: Job,
  //     },
  //     {
  //       path: "/connection",
  //       name: "connection",
  //       component: Connection,
  //     },
  // ]
    
  },

  {
    path: "/first",
    name: "First",
    component: First,
  },

  {
    path: "/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/yewu/:id",
    name: "Yewu",
    component: Yewu,
  },
  {
    path:"/aptitude",
    name:"Aptitude",
    component:Aptitude,
  },
  {
    path: "/aptitudeDetail/:id",
    name: "AptitudeDetail",
    component: AptitudeDetail,
  },
  {
    path: "/anli",
    name: "Anli",
    component: Anli,
  },
  {
    path: "/anli/:id",
    name: "AnliDetail",
    component: AnliDetail,
  },
  {
    path: "/pro/:id",
    name: "show",
    component: Show,
  },
  {
    path: "/pro/detail/:id",
    name: "ShowDetail",
    component: ShowDetail,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/news/detail/:id",
    name: "newsDetail",
    component: NewsDetail,
  },
  {
    path: "/job",
    name: "job",
    component: Job,
  },
  {
    path: "/connection",
    name: "connection",
    component: Connection,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
