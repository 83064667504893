<template>
  <div>
    <app-lunbo></app-lunbo>
     <fanwei></fanwei>
     <liucheng></liucheng>
     <about></about>
     <anli></anli>
     <ditu></ditu>
     <chanpin></chanpin>
    <lianxi></lianxi>

   
  </div>
 
</template>

<script>
import AppLunbo from "@/components/AppLunbo.vue"
import Fanwei from "@/components/Fanwei.vue"
import Liucheng from "@/components/Liucheng.vue"
import About from "@/components/About.vue"
import Anli from "@/components/Anli.vue"
import Ditu from "@/components/Ditu.vue"
import Chanpin from "@/components/Chanpin.vue"
import Lianxi from "@/components/Lianxi.vue"
export default {
  data () {
    return {
    }
  },

  components: {AppLunbo,Fanwei,Liucheng,About,Anli,Ditu,Chanpin,Lianxi},

  methods: {}
}
</script>

<style scoped lang="scss">

</style>