<template>
    <div id="allmap" class="allmap" />
</template>

<script>
import { BMapLoader } from "@/utils/mapLoader";

export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "3.8.5",
      map: null,

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 1000,
      },
    };
  },

  created() {
    this.initMap();
  },
  destroyed() {
    this.map.destroy();
  },

  methods: {
    goTarget(href) {
      window.open(href, "_blank");
    },

    initMap() {
      this.$nextTick(() => {
        BMapLoader().then((BMap) => {
          const map = new BMap.Map("allmap", { enableMapClick: false });
          this.map = map;
          map.disableKeyboard(); // 禁用键盘操作地图
          map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
          // 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
          const point = new BMap.Point(114.45486174407158,38.053310289283175);
          map.centerAndZoom(point, 13);

           var marker = new BMapGL.Marker(point);
              map.addOverlay(marker);


              var label = new BMapGL.Label("甲壳虫消防-河北省石家庄市桥西区新华路靶场街交叉口南行100米路西华业大厦B座", {
                // 创建文本标注
                position: point, // 设置标注的地理位置
                offset: new BMapGL.Size(-200, -48), // 设置标注的偏移量
              });
              map.addOverlay(label);
              label.setStyle({
                // 设置label的样式
                color: "#fff",
                fontSize: "12px",
                backgroundColor: '#409eff',
                borderRadius: "3px",
                border:'none',
                padding:'3px 5px',
                fontWeight:'normal'
              });



        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.allmap {
  width: 1180px;
  height: 600px;
  margin: auto;
  // position: absolute;
  // right: 0px;
  // bottom: 0px;
}
</style>

