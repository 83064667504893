import request from '@/utils/request'

// 查询产品类别列表
export function listProType(query) {
  return request({
    url: '/ws/proType/list',
    method: 'get',
    params: query
  })
}

