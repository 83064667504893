<template>
  <div class="liuchengbox">
			<div class="liucheng_title">
				<h2>业务流程</h2>
				<p> OPERATION FLOW</p>
			</div>
			<div class="liucheng">
				<ul>
					<li>
						<p>
							<span>NO.1</span>
							来电咨询
						</p>
					</li>
					<li>
						<p>
							<span>NO.2</span>
							测量现场
						</p>
					</li>
					<li>
						<p>
							<span>NO.3</span>
							提供消防方案
						</p>
					</li>
					<li>
						<p>
							<span>NO.4</span>
							签订合同
						</p>
					</li>
					<li>
						<p>
							<span>NO.5</span>
							设计图纸
						</p>
					</li>
					<li>
						<p>
							<span>NO.6</span>
							现场施工
						</p>
					</li>
					<li>
						<p>
							<span>NO.7</span>
							检测验收
						</p>
					</li>
					<div class="clear"></div>
				</ul>



			</div>
		</div>
</template>

<script>
export default {
  data () {
    return {
    }
  },

  components: {},

  methods: {}
}
</script>

<style scoped>

.liuchengbox{ width:100%; background:url(@/assets/images/lcbg.jpg) no-repeat center / cover; padding:20px 0}
.liucheng_title{width:1150px; margin:0 auto; text-align:center; color:#FFF; padding:20px 0}
.liucheng_title h2{ font-size:28px; line-height:40px}
.liucheng_title p{ line-height:24px; font-size:16px;text-transform:uppercase}
.liucheng{ width:1150px; margin:0 auto; padding:20px 0}
.liucheng ul{ width:100%; display:flex; align-items:center;justify-content:center}
.liucheng ul li{ display:inline; width:160px; position:relative; background:url(@/assets/images/dayu.png) no-repeat 80% center}
.liucheng ul li:nth-child(3){ width:190px;}
.liucheng ul li:nth-child(7){ background:none; width:auto}
.liucheng ul li p{ padding:10px; font-size:18px; color:#FFF}
.liucheng ul li p span{ display:block}
</style>