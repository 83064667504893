<template>
    <div class="footer">
        <div class="footer_c">

            <div class="link-box friendBox">
                <h4 style="color:white;margin-bottom: 5px;">分站：</h4>
                <ul>

                    <ul class="hbjc_cpflzbt">

                        <a href="">河北</a> <span style="color:white;">|</span>
                        <a href="">石家庄</a> <span style="color:white;">|</span>
                        <a href="">北京</a> <span style="color:white;">|</span>
                        <a href="">天津</a> <span style="color:white;">|</span>
                        <a href="">唐山</a> <span style="color:white;">|</span>
                        <a href="">秦皇岛</a> <span style="color:white;">|</span>
                        <a href="">邯郸</a> <span style="color:white;">|</span>
                        <a href="">邢台</a> <span style="color:white;">|</span>
                        <a href="">保定</a> <span style="color:white;">|</span>
                        <a href="">张家口</a> <span style="color:white;">|</span>
                        <a href="">承德</a> <span style="color:white;">|</span>
                        <a href="">沧州</a> <span style="color:white;">|</span>
                        <a href="">廊坊</a> <span style="color:white;">|</span>
                        <a href="">衡水</a> <span style="color:white;">|</span>
                        <a href="">山西</a> <span style="color:white;">|</span>
                        <a href="">内蒙古</a> <span style="color:white;">|</span>
                        <a href="">沈阳</a> <span style="color:white;">|</span>
                        <a href="">拉萨</a> <span style="color:white;">|</span>
                        <a href="">西安</a> <span style="color:white;">|</span>

                        <a href="">更多...</a>
                    </ul>


                </ul>
            </div>


            <p class="l" style="font-size:18px;"> <a href="/" title="石家庄消防设计">石家庄消防设计</a>|<a href="/"
                    title="消防验收">消防验收</a>|<a href="/" title="消防安装">消防安装</a>|<a href="/" title="消防器材">消防器材</a>|
                <a href="/" title="消防维护">消防维护</a>|<a href="/" title="消防保养">消防保养</a>|<br><a href="/"
                    title="河北消防手续报批">河北消防手续报批</a>|<a href="/" title="消防工程施工">消防工程施工</a>|<a href="/"
                    title="消防手续报审">消防手续报审</a>|<a href="/" title="消防检测">消防检测</a><br />
                公司地址：石家庄市桥西区新华路与靶场街西南角华业商务广场B座 <br />
                公司电话：13933856985<br />
                <a href="http://beian.miit.gov.cn/" class="cptColor">冀ICP备2023009466号</a><br />
            </p>
            <div class="erweima r">
                <img src="@/assets/img/uploadfile/2017/0825/20170825101522665.jpg">

            </div>
            <div class="clear"></div>



        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },

    components: {},

    methods: {}
}
</script>

<style scoped>
/*footer*/
/*footer*/
.footer {
    width: 100%;
    height: 270px;
    overflow: hidden;
    background: #222;
}

.footer p {
    letter-spacing: 2px;
    text-align: left;
    height: 122px;
    padding: 30px 0 0 220px;
    background: url(@/assets/images/logo1.png) no-repeat 30px 40px;
    background-size: 120px,120px;
    border-color: #fff;
    line-height: 27px;
    font-size: 12px;
    color: #e7e7e7;
}

.footer strong {
    font-size: 14px;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer a:hover {
    color: #fff;
}

.footer_c .erweima {
    margin: 10px 0 0 0;
    float: right;
    margin-top: -150px;
}

.footer_c {
    width: 1100px;
    margin: 0 auto;
    padding: 20px 0 0 0
}</style>