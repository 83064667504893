import axios from 'axios' // 手动创建一个 axios 对象, 参考: https://github.com/axios/axios#creating-an-instance

import router from '@/router';





const request = axios.create({ 
    // 请求配置参考: https://github.com/axios/axios#request-config // 根据不同环境设置 baseURL, 最终发送请求时的URL为: baseURL + 发送请求时写URL , // 比如 get('/test'), 最终发送请求是: /dev-api/test // baseURL: '/dev-api',
    //baseURL: process.env.VUE_APP_BASE_API,
    baseURL: process.env.VUE_APP_BASE_API,  //  '/dev-api'
    timeout: 3000 // 请求超时 
})



//请求拦截器
request.interceptors.request.use( config =>{
    //判断localstorage中是否有token
    //有, header中带伤token
    var token = localStorage.getItem("token");
    if(token){
        config.headers.Authoration = "Bearer " + token;
    }
  

    return config;
},error=>{
    loading.close();
    console.log("请求出异常了");
})



//响应拦截器
request.interceptors.response.use(response => {

    var resp = response.data;
    // if(resp.code == 401){
    //     router.push("/login");
    //   }

    return resp;
}, error => {
    
    return Promise.reject(error);
})


export default request // 导出 axios 对象