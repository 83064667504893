<template>
  <div>
    <div class="product_title_fenlei">
      <div class="product_title">
        <h2><strong>业务范围</strong> / PRODUCTS SHOW</h2>
      </div>
    </div>
    <div class="product" style="height: 50px; padding: 0">
      <div class="fenlei">
        <ul >
          <li v-for="item in yewuList" :key="item.id" @click="yewuDetail(item.id)">
            <a style="font-size: 18px" :title="item.title">{{item.title}}</a>
            <div class="hid hidden" id="fs96"></div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { listYewu } from "@/api/yewu";
export default {
  data() {
    return {yewuList:[]};
  },

  created(){
    this.getYewu();
  },
  components: {
    
  },

  methods: {
    yewuDetail(id){
      this.$router.push({
        name:'Yewu',
        params:{
          id:id
        }
      })
      
    },
    getYewu(){
      listYewu().then(resp=>{
        this.yewuList=resp.rows;
      })
    }
  },
};
</script>

<style scoped>
/*product_title_fenlei*/
.product_title_fenlei {
  padding: 65px 0;
}
a{
  text-decoration: none;
}
.product_title {
  width: 1150px;
  margin: 0 auto;
  border: 1px solid #ce2029;
  height: 42px;
}
.product_title h2 {
  text-align: center;
  background: #fff;
  height: 45px;
  line-height: 45px;
  width: 380px;
  margin: 0 auto;
  margin-top: -23px;
  font-size: 18px;
  color: #000000;
}
.product_title h2 span {
  color: #df1818;
  font-weight: bold;
}
.product_title h2 strong {
  color: #ce2029;
  font-size: 28px;
  padding: 0 20px 0 0;
}
.product_title p {
  text-align: center;
  background: #fff;
  height: 40px;
  line-height: 40px;
  width: 750px;
  margin: 0 auto;
  font-size: 14px;
  color: #888888;
  text-transform: uppercase;
}

/*product*/
.product {
  background: url(@/assets/images/chanpinbg.jpg) no-repeat center bottom;
  padding: 20px 0 50px 0;
  height: 519px;
  margin: 0 auto;
  
}
.fenlei {
  width: 1180px;
  margin: 0 auto;
  margin-bottom: 25px;
  background: url(@/assets/images/fenlei.jpg) repeat-x left top;
  position: relative;
  top: -25px;
  text-align: center;
}
.fenlei ul {
  overflow: hidden;
  /* border: 1px green solid; */
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.fenlei li {
  /* list-style: none; */
  line-height: 62px;
  float: left;
  height: 72px;
  text-align: center;
}
.fenlei li a {
  display: block;
  height: 62px;
  line-height: 62px;
  color: #444;
  padding: 0 24px;
  font-size: 14px;
  border-right: 1px solid #d8d8d8;
}
.fenlei li a:hover {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background: url(@/assets/images/redhover.png) no-repeat center top;
  height: 72px;
  border-right: 0 solid #d8d8d8;
  cursor: pointer;
}
.fenlei li .hid a {
  width: 146px;
  padding: 0 0 0 30px;
  height: 40px;
  line-height: 40px;
  background: #f9e5bd;
  margin: 0 auto;
  margin-bottom: 5px;
  color: #5b2b11;
}
.fenlei li .hid a:hover {
  color: #000;
}
</style>