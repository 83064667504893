<template>
  <div class="about">
    <div class="about_con l" style="width: 650px">
      <h2>公司简介<span>/ ABOUT US</span></h2>
      <p class="text" v-html="this.firstShow"
        style="font-size: 16px;margin-left: 10px;text-indent: 2em;"></p>


      <div class="about_more" ><a href="/company">+ more</a></div>
    </div>

    <el-carousel height="240px" :interval="2000" arrow="always">
      <el-carousel-item v-for="item in lunboList" :key="item.id">
        <img :src="item.imgUrl" :alt="item.title" @click="toDetail(item.id)" style="cursor: pointer;" />
      </el-carousel-item>
    </el-carousel>

    <div class="clear"></div>
  </div>
  <!-- about end -->
</template>

<script>
import { listCompany } from "@/api/company";
import { listLunbo } from "@/api/aptitude";
export default {
  data() {
    return {
      companyList: [],
      lunboList: [],
      firstShow:null,
    };
  },
  created() {
    this.getCompany();
    this.getLunbo();
  },
  components: {},

  methods: {
    toDetail(id) {
      this.$router.push({
        name: 'AptitudeDetail',
        params: {
          id: id
        }
      })
      

    },
    getCompany() {
      listCompany().then(res => {
        this.companyList = res.rows;
        this.firstShow=this.companyList[0].firstShow;
      })
    },
    getLunbo() {
      listLunbo().then(res => {
        this.lunboList = res.rows;
      })
    }
  },
};
</script>

<style scoped>
/*about*/
.about {
  width: 1130px;
  margin: 30px auto 0 auto;
  border: 1px solid #e3e3e3;
  padding: 37px 25px;
}

.about_con {
  float: left;
  width: 595px;
  /* border-right: 1px red solid; */
}

.about_con h2 {
  height: 25px;
  line-height: 25px;
  background: url(@/assets/images/jianjie_icon.jpg) no-repeat left center;
  padding: 0 0 0 20px;
  font-size: 18px;
  color: #ce2029;
}

.about_con h2 span {
  font-size: 14px;
  color: #000000;
  padding: 0 0 0 15px;
}

.about_con p {
  padding: 20px 0 0 0;
  line-height: 23px;
  font-size: 12px;
  color: #777777;
}

.about .about_more {
  width: 77px;
  height: 25px;
  line-height: 25px;
  background: #ce2029;
  margin: 15px 0 0 0;
  text-align: center;
  text-transform: uppercase;
}

.about .about_more a {
  text-decoration: none;
  color: #fff;
}

.about .about_pic {
  padding: 4px 0 0 0;
}

.about_con h2 a:hover {
  color: #fff;
  background: #88de22;
}


.el-carousel {
  float: left;
  width: 320px;
  border: 1px #ddd solid;
  margin-left: 130px;
}
.el-carousel img{
  width: 320px;
  height: 240px;
}

.text {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}</style>