<template>
	<!--底部三个联系方式-->
	<div class="lanmuc-liaxy">
		<div class="cpxxyd">
			<div class="lix"><b>联系方式</b></div>
			<div class="lxnr">
				<ul>
					<li v-for="item in lianxiList" :key="item.id">
						<p>{{ item.phone }}</p>
						<p><img :src="item.erWei" /></p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { listLianxi } from "@/api/lianxi";
export default {
	data() {
		return {
			lianxiList: []
		}
	},
	created() {
		this.getLianxi();
	},
	components: {},

	methods: {
		getLianxi() {
			listLianxi().then(res => {
				this.lianxiList = res.rows;
			})
		}
	}
}
</script>

<style scoped>
.lanmuc-liaxy {
	width: 100%;
	height: auto;
}

.lanmuc-liaxy .cpxxyd {
	width: 1200px;
	height: 250px;
	border: 1px solid #ccc;
	margin: 20px auto;
}

.lanmuc-liaxy .lix {
	width: 90%;
	float: left;
	height: 40px;
	padding: 0 5%;
	text-align: center;
	font-size: 20px;
	line-height: 40px;
	color: #333;
	border-bottom: 1px solid #ccc;
}

.lanmuc-liaxy .lxnr {
	width: 100%;
	height: auto;
	float: left;
	margin-top: 20px;
	
}
.lanmuc-liaxy .lxnr ul{
	display: flex;
  flex-direction: row;
  justify-content: center;
}

.lanmuc-liaxy .lxnr ul li {
	width: 31%;
	height: auto;
	float: left;
	margin: 0 1%;
	text-align: center;
	font-size: 18px;
	line-height: 20px
}

.lanmuc-liaxy .lxnr ul li img {
	width: 160px;
	height: 160px;
}
</style>