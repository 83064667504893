<template>
  <div class="main_r">
    <div class="second_con">
      <div class="weizhi"><span>你当前所在的位置：</span> <a href="/first">网站首页</a>&nbsp; >&nbsp;产品展示&nbsp; >&nbsp;{{ this.title }}
      </div>
      <div class="m10_pro_list">
        <div class="ki24">
          <a v-for="item in proList" :key="item.id" :title="item.name" @click="toDetail(item.id)"
            style="cursor: pointer;">
            <div class="ki241">
              <div class="ki241img"><img :src="item.imgUrl" :alt="item.name" border="0" /></div>
              <div class="ki2411">{{ item.name }}</div>
              <div class="more"><span>查看详情</span>+ more</div>
              <div class="hszz1"></div>
            </div>
          </a>

          <div class="clear"></div>
          <el-pagination style="text-align: center;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNum" :page-sizes="[9, 15, 30, 60]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>





      </div>
    </div>


  </div>
</template>

<script>
import { listPro } from "@/api/pro";
import { listProType } from "@/api/proType";
export default {
  data() {
    return {
      typeId: this.$route.params.id,
      title: null,

      //总条数
      total: 0,
      pageNum: 1,
      pageSize: 9,
      proList: [],
      typeList: [],
    }
  },
  created() {
    this.getType();
    this.getPro();
  },
  components: {},

  methods: {

    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getAptitude();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getAptitude();
    },

    toDetail(id) {
      this.$router.push({
        name: 'ShowDetail',
        params: {
          id: id
        }
      })
      // window,location.reload();
    },

    getPro() {
      listPro({
        typeId: this.typeId, pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.proList = res.rows;
        this.total = res.total;

      })
    },
    getType() {
      listProType().then(res => {
        this.typeList = res.rows;
        this.typeList.forEach(t => {
          if (this.typeId == t.id) {
            this.title = t.title;
          }
        });
      })
    }
  }
}
</script>

<style scoped>
/*erji*/


.main_r {
  width: 1180px;
  min-height: 0;
  overflow: hidden;
  margin: 30px auto 20px auto;
}


.second_con {
  background: #fff;
  min-height: 957px;
  border: 1px solid #dadada;
  margin: 0 0 0 10px;
}

.second_con .weizhi {
  background: #ce2029;
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  padding-left: 28px;
  color: #ebebeb
}

.second_con .weizhi span {
  display: inline-block;
  font-size: 12px;
  color: #ebebeb;
  line-height: 33px;
  height: 37px;
  font-weight: normal;
  padding-left: 20px;
}

.second_con .weizhi a {
  color: #ebebeb;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}

.second_con .weizhi a:hover {
  color: #fff;
}

.second_con .second {
  padding: 20px 40px 10px 40px;
  font-size: 14px;
  line-height: 29px;
  color: #484848;
}

.second_con .second .jianjie {
  line-height: 28px;
  font-size: 14px;
}

.lanmuc-liaxx {
  width: 100%;
  height: auto;
  margin: 0 auto;

}

.lanmuc-liaxx .cpxxyd {
  width: 95%;
  float: left;
  height: 240px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  margin-right: 3%;
}

.lanmuc-liaxx .lix {
  width: 90%;
  float: left;
  height: 40px;
  padding: 0 5%;
  text-align: center;
  background: #ccc;
  font-size: 20px;
  line-height: 40px;
  background: #ce2029;
  color: #fff;
}

.lanmuc-liaxx .lxnr {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 20px;
}

.lanmuc-liaxx .lxnr ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.lanmuc-liaxx .lxnr ul li {
  width: 31%;
  height: auto;
  float: left;
  margin: 0 1%;
  text-align: center;
  font-size: 18px;
  line-height: 20px
}

.lanmuc-liaxx .lxnr ul li img {
  width: 160px;
  height: 160px;
}

.m10_pro_list {
  overflow: hidden;
  margin: 0 auto;
  width: 920px;
  padding: 30px 0 0 50px
}



.ki24 a {
  /* float: left; */
  display: inline-block;
  margin: 0 54px 20px 0;
  text-align: center
}

.ki241 {
  width: 252px;
  height: 330px;
  float: left;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.ki241:hover .hszz1 {
  background: rgba(0, 0, 0, 0);
  transition: 1s all;
}

.ki24 img {
  float: left;
  transition: 1s all;
  border: 1px solid #e9e9e9;
  width: 248px;
  height: 250px;
}

.ki241img {
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.ki241:hover .ki241img img {
  transform: scale(1.1);
  transition: 1s all;
}

.ki2411 {
  line-height: 48px;
  color: #333333;
  font-size: 14px;
}

.ki24 .hszz1 {
  width: 250px;
  height: 250px;
  transition: 1s all;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0px;
}

.ki24 .more {
  width: 250px;
  margin: 0 auto;
  height: 24px;
  background: #2d2d2d
}

.ki24 .more {
  display: block;
  line-height: 24px;
  color: #ddd;
  text-transform: uppercase;
  font-size: 12px;
}

.ki24 .more span {
  width: 122px;
  text-align: center;
  float: right;
  /* display: inline-block; */
  background: #ce2029;
  color: #ddd
}
</style>