<template>
  <div class="menuDiv">
    <ul>
      <li><a href="/" title="网站首页">网站首页</a></li>

      <li><a href="/company" title="公司简介">公司简介</a></li>

      <li>
        <a  title="业务范围">业务范围</a>
        <ul>
          <li v-for="item in yewuList" :key="item.id" @click="yewuDetail(item.id)"><a :title="item.title">{{item.title}}</a></li>
        </ul>
      </li>

      <li><a href="/aptitude" title="企业资质">企业资质</a></li>

      <li><a href="/anli" title="客户案例">客户案例</a></li>

      <li>
        <a  title="产品展示">产品展示</a>
        <ul >
          <li v-for="item in proTypeList" :key="item.id" @click="proShow(item.id)"><a  :title="item.title">{{ item.title }}</a></li>
        </ul>
      </li>

      <li><a href="/news" title="新闻资讯">新闻资讯</a></li>
      <li><a href="/job" title="诚聘英才">诚聘英才</a></li>

      <li><a href="/connection" title="联系我们">联系我们</a></li>
    </ul>
  </div>
</template>

<script>
import { listYewu } from "@/api/yewu";
import { listProType } from "@/api/proType";
export default {
  data() {
    return {
      yewuList:[],
      proTypeList:[]
    };
  },
  created(){
    this.getYewu();
    this.getProType();
  },
  components: {},

  methods: {

    yewuDetail(id){
      this.$router.push({
        name:'Yewu',
        params:{
          id:id
        }
      })
      window.location.reload();
    },

    proShow(id){
      this.$router.push({
        name:'show',
        params:{
          id:id
        }
      })
      window.location.reload();
    },

    getYewu(){
      listYewu().then(resp=>{
        this.yewuList=resp.rows;
      })
    },
    getProType(){
      listProType().then(res=>{
        this.proTypeList=res.rows;
      })
    }
  },
};
</script>

<style scoped>


.menuDiv {
  height: 70px;
  margin: 0 auto;
  background: #ce2029;
  position: relative;
}

/* 去掉a标签的下划线 */
.menuDiv a {
  text-decoration: none;
}

.menuDiv ul {
  height: 70px;
  width: 1180px;
  margin: 0 auto;
}

.menuDiv li {
  float: left;
  line-height: 70px;
  height: 70px;
  width: 115px;
  /* background: url(@/assets/images/navline.jpg) no-repeat right center; */
  position: relative;
}

/* 设置二级菜单绝对定位，并隐藏 */
.menuDiv>ul>li>ul {
  display: none;
  position: absolute;
  left: -15px;
  top: 70px;
  font-size: 12px;
  width: 185px;
  text-align: center;
  color: #4f4f4f;
  /* background: #ce2029; */
  z-index: 9999;
}

/* 设置二级菜单的li的样式 */
.menuDiv>ul>li>ul>li {
  float: none;
  margin-bottom: -25px;
}

/* 鼠标放在一级菜单上，显示二级菜单 */
.menuDiv>ul>li:hover ul {
  display: block;
}


/* 一级菜单 */
.menuDiv>ul>li>a {
  width: 120px;
  color: #fff;
  font-size: 14px;
  line-height: 70px;
  height: 70px;
  text-align: center;
  text-decoration: none;
  display: block;

}


/* 在一级菜单中，鼠标放上去的样式 */
.menuDiv>ul>li>a:hover {
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

/* 二级菜单 */
.menuDiv>ul>li>ul>li>a {
  display: block;
  color: #fff;
  line-height: 45px;
  height: 45px;
  font-size: 13px;
  font-weight: normal;
  width: 185px;
  border-bottom: 1px solid #fff;
  background-color: #ce2029;

}


/* 在二级菜单中，鼠标放上去的样式 */
.menuDiv>ul>li>ul>li>a:hover {
  font-size: 13px;
  background: #b76367;
  color: #ffffff;
  cursor: pointer;
}

/* -------------菜单css代码----------end---------- */
</style>