<template>
  <div class="header">
    <div class="top">
      <h1><span>河北江成建筑工程有限公司</span></h1>
      <div class="logo">
        <a href="/">
          <img src="@/assets/images/company.png" width="550px" height="75px" border="0" />
        </a>
      </div>
      <div class="wenzi"><strong>致力技术创新 打造卓越品质</strong><br /></div>
      <div class="toperweima">
        <img :src="this.erWei" width="100" height="100" />
      </div>
      <div class="tel">
        <img :src="this.phone" width="282px" height="140px" border="0" />
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { listTop } from "@/api/top";
export default {
  data() {
    return {
      topList: [],
      erWei: null,
      phone: null,
    };
  },
  created() {
    this.getTop();
  },

  components: {},

  methods: {
    getTop() {
      listTop().then(resp => {
        this.topList = resp.rows;
        this.erWei = this.topList[0].erWei;
        this.phone = this.topList[0].phone;
      })
    }
  },
};
</script>

<style scoped>
.header {
  height: 150px;
  overflow: hidden;
  width: 100%;
  background: #fff;
}

.header .top {
  width: 1180px;
  margin: 0 auto;
  padding: 35px 0 0 0;
}

.header .top h1 span {
  display: none;
}

.header .top .logo {
  float: left;
  text-align: left;
}

.header .top .wenzi {
  float: left;
  padding: 42px 0 0 20px;
  line-height: 25px;
  color: #4e4e4e;
  font-size: 16px;
}

.header .top .wenzi strong {
  color: #ce2029;
}

.toperweima {
  float: left;
  margin-left: 20px;
}

.header .top .tel {
  float: right;
  text-align: left;
  overflow: hidden;
}
</style>