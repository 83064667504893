<template>
  <!-- 轮播图 -->
  <div class="lunbo">
    <el-carousel height="600px" :interval="2000" arrow="always" ref="lunbo" >
      <el-carousel-item v-for="item in lunboList" :key="item.id">
        <img :src="item.imageUrl" :alt="item.title" style="width: 100%;height: 600px;" >
      </el-carousel-item>

    </el-carousel>
  </div>
  <!-- 轮播图end -->
</template>

<script>
import { listBanner } from "@/api/banner";

export default {


  data() {
    return {
      lunboList: []
    };
  },

  created() {
    this.getList();
  },

  components: {},

  methods: {
    // linkTo(){
    //   let activeIndex =this.$refs.lunbo.activeIndex;
    //   window.open(this.lunboList[activeIndex].link,"_blank")
    // }

    getList(){
      listBanner().then(resp=>{
        this.lunboList=resp.rows;
      })
    },

  },
};
</script>

<style scoped>
.el-carousel__item {
  color: #475669;
  text-align: center;
  margin: 0;
  z-index: -1;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>