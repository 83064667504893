var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuDiv"},[_c('ul',[_vm._m(0),_vm._m(1),_c('li',[_c('a',{attrs:{"title":"业务范围"}},[_vm._v("业务范围")]),_c('ul',_vm._l((_vm.yewuList),function(item){return _c('li',{key:item.id,on:{"click":function($event){return _vm.yewuDetail(item.id)}}},[_c('a',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])}),0)]),_vm._m(2),_vm._m(3),_c('li',[_c('a',{attrs:{"title":"产品展示"}},[_vm._v("产品展示")]),_c('ul',_vm._l((_vm.proTypeList),function(item){return _c('li',{key:item.id,on:{"click":function($event){return _vm.proShow(item.id)}}},[_c('a',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])}),0)]),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/","title":"网站首页"}},[_vm._v("网站首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/company","title":"公司简介"}},[_vm._v("公司简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/aptitude","title":"企业资质"}},[_vm._v("企业资质")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/anli","title":"客户案例"}},[_vm._v("客户案例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/news","title":"新闻资讯"}},[_vm._v("新闻资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/job","title":"诚聘英才"}},[_vm._v("诚聘英才")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/connection","title":"联系我们"}},[_vm._v("联系我们")])])
}]

export { render, staticRenderFns }