import request from '@/utils/request'

// 查询联系我们列表
export function listConnection(query) {
  return request({
    url: '/ws/connection/list',
    method: 'get',
    params: query
  })
}

