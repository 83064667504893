import request from '@/utils/request'

// 查询新闻资讯列表
export function listNews(query) {
  return request({
    url: '/ws/news/list',
    method: 'get',
    params: query
  })
}

