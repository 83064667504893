<template>
  <div>
    <yi></yi>
    <er></er>
    <san></san>
  </div>
</template>

<script>
import Yi from './yi.vue'
import Er from './er.vue'
import San from './san.vue'
export default {
  data () {
    return {
    }
  },

  components: {Yi,Er,San},

  methods: {}
}
</script>

<style scoped>
</style>