import request from '@/utils/request'

// 查询产品展示列表
export function listPro(query) {
  return request({
    url: '/ws/pro/list',
    method: 'get',
    params: query
  })
}

// 查询首页产品展示列表
export function listShow(query) {
  return request({
    url: '/ws/pro/show',
    method: 'get',
    params: query
  })
}
