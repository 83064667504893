<template>
  <div class="index_link" style="text-align: center;">
    <div class="kuan"><span style="font-size: 13px;color: #eee; font-weight: bold;margin-right: 50px;">友情链接</span><a v-for="item in linkList" :key="item.id" :href="item.url" target="_blank">{{ item.name }}</a></div>
  </div>
</template>

<script>
import { listLink } from "@/api/link";
export default {
  data() {
    return {
      linkList: []
    }
  },

  created() {
    this.getLink();
  },
  components: {},

  methods: {
    getLink() {
      listLink().then(res => {
        this.linkList = res.rows;
      })
    }
  }
}
</script>

<style scoped>
/*index_link*/
.index_link {
  background: #2d2d2d;
  /*height:48px;*/
  line-height: 48px;

}

.plug_link {
  line-height: 48px;
  width: 1180px;
  margin: 0 auto;

}

.plug_link dl {
  line-height: 48px;

}

.plug_link span {
  float: left;
  height: 48px;
  color: #b4b4b4;
  text-align: center;
}

.plug_link dt {
  float: left;
  padding: 0 25px;
  text-align: center;
  color: #b4b4b4;
  font-weight: bold;
  font-size: 14px;

}
.kuan{
  margin: 0 auto;
  width: 1200px;

}

a {
  display: inline-block;
  color: #b4b4b4;
  font-size: 12px;
  line-height: 48px;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  margin: 0 20px;
}

a:hover {
  color: #ddd
}
</style>