<template>
  <div class="case" >
    <h2>
      <a href="/anli" title="工程案例">工程展示</a><span>/ SEALING MACHINE</span>
    </h2>
    <div class="case_con" >
      <div class="marquee2" id="picscroll2" style="overflow: hidden; position: relative;width: 1180px;height: 345px;white-space: nowrap;">
        <ul>
          <li v-for="item in anliList" :key="item.id" style="cursor: pointer;">
            <div class="pic">
              <a :title="item.title" @click="toDetail(item.id)">
                <img
                  :src="item.imgUrl" :alt="item.title" /></a>
            </div>
            <p>
              <a :title="item.title" @click="toDetail(item.id)">{{item.title}}</a>
            </p>
            <div class="case_more">
              <a href="/anli">+ more</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { listShow } from "@/api/anli";
export default {
  data() {
    return {
      anliList: []
    };
  },

  created() {
    this.getAnli();
  },

  mounted() {

  },

  components: {},

  methods: {
    toDetail(id){
            this.$router.push({
                name:'AnliDetail',
                params:{
                    id:id
                }
            })
            // window,location.reload();
        },
    getAnli() {
      listShow().then(res => {
        this.anliList = res;
      })
    }
  },
};
</script>

<style scoped>
/*case*/
.case {
  box-sizing: content-box;
  background: #f3f3f3;
  height: 480px;
}

.case a {
  text-decoration: none;
}

.case h2 {
  height: 56px;
  overflow: hidden;
  line-height: 56px;
  text-align: center;
  margin: 0 0 35px 0;
}

.case h2 a {
  font-size: 22px;
  color: #ce2029;
}

.case h2 span {
  font-size: 22px;
  color: #000000;
  padding: 0 0 0 20px;
  text-transform: uppercase;
  font-weight: normal;
}

.case .r {
  font-size: 14px;
  font-weight: normal;
  padding-right: 20px;
}

.case .case_con {
  width: 1180px;
  margin: 0 auto;
  padding: 10px 0 0 0;
}

.marquee2 li {
  width: 325px;
  float: left;
  height: 345px;
  background: #fff;
  position: relative;
  padding: 23px;
}

.marquee2 li:nth-of-type(2) {
  margin: 0px 33px;
}

.marquee2 .pic {
  width: 100%;
  height: 228px;
  margin: 0 auto;
}

.marquee2 .pic img {
  width: 100%;
  height: 100%;
}

.marquee2 p {
  text-align: center;
  line-height: 50px;
  font-size: 12px;
}

.marquee2 p a {
  color: #444444;
  font-size: 14px;
}

.marquee2 p a:hover {
  color: #6cb713;
}

.marquee2 .case_more {
  width: 89px;
  margin: 0 auto;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #e33131;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #e33131;
}

.marquee2 .case_more a {
  color: #e33131;
}
</style>