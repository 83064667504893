<template>
  <div class="main_r">
    <div class="second_con">
      <div class="weizhi"><span>你当前所在的位置：</span> <a href="/first">网站首页</a>&nbsp; >&nbsp;联系我们&nbsp;
      </div>
      <div class="second">
        <div class="info_content">
          <div class="ql-editor" v-html="this.content" style="margin-top: 20px;text-indent: 2em;margin-bottom: 30px;"></div>
          <ditu style="width: 1000px;margin-bottom: 30px;"></ditu>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="clear"></div> -->
</template>
  
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { listConnection } from "@/api/connection";
import Ditu from "@/components/Ditu.vue"
export default {
  data() {
    return {
      connectionList: [],
      content: null,
    }
  },
  created() {
    this.getConnection();
  },
  components: {Ditu},

  methods: {
    getConnection() {
      listConnection().then(res => {
        this.connectionList = res.rows;
        this.content = this.connectionList[0].content;
      })
    }
  }
}
</script>
  
<style scoped>
.main_r {
  width: 1180px;
  min-height: 0;
  overflow: hidden;
  margin: 30px auto 20px auto;
}


.second_con {
  background: #fff;
  min-height: 800px;
  border: 1px solid #dadada;
  margin: 0 0 0 10px;
}

.second_con .weizhi {
  background: #ce2029;
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  padding-left: 28px;
  color: #ebebeb
}

.second_con .weizhi span {
  display: inline-block;
  font-size: 12px;
  color: #ebebeb;
  line-height: 33px;
  height: 37px;
  font-weight: normal;
  padding-left: 20px;
}

.second_con .weizhi a {
  color: #ebebeb;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}

.second_con .weizhi a:hover {
  color: #fff;
}

.second_con .second {
  padding: 20px 40px 10px 40px;
  font-size: 14px;
  line-height: 29px;
  color: #484848;
}

.second_con .second .jianjie {
  line-height: 28px;
  font-size: 14px;
}
</style>