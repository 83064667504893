import request from '@/utils/request'

// 查询企业资质列表
export function listAptitude(query) {
  return request({
    url: '/ws/aptitude/list',
    method: 'get',
    params: query
  })
}
// 查询企业资质轮播列表
export function listLunbo(query) {
  return request({
    url: '/ws/aptitude/lunbo',
    method: 'get',
    params: query
  })
}


