
import request from '@/utils/request'

// 查询轮播图管理列表
export function listBanner(query) {
    return request({
      url: '/ws/banner/list',
      method: 'get',
      params: query
    })
  }